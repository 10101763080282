export const environment = {
  production: true,
  development: false,
  staging: false,
  useEmulators: false,
  firebase: {
    projectId: 'dein-ruf-de-01',
    appId: '1:853164585671:web:eceddd5511d03f12f944e2',
    databaseURL:
      'https://dein-ruf-de-01-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'dein-ruf-de-01.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyACiAfrgb2B0smd3DePqOMZ0crwdCIxn7s',
    authDomain: 'dein-ruf-de-01.firebaseapp.com',
    messagingSenderId: '853164585671',
    measurementId: 'G-SPJHK5XKD2',
  },
  facebook: {
    pixel: '360862422626512',
  },
  // firebase: {
  //   projectId: 'dr-extensions-2',
  //   appId: '1:1059447706567:web:0dd047b13aaae5f12b3fe2',
  //   databaseURL:
  //     'https://dr-extensions-2-default-rtdb.europe-west1.firebasedatabase.app',
  //   storageBucket: 'dr-extensions-2.appspot.com',
  //   locationId: 'europe-west',
  //   apiKey: 'AIzaSyCPt_X-7MD-i0czt1K0L8HLSCdt2D_aulc',
  //   authDomain: 'dr-extensions-2.firebaseapp.com',
  //   messagingSenderId: '1059447706567',
  //   measurementId: 'G-P4GD29RP5D',
  // },
  // firebase2: {
  //   projectId: 'app-deinrufde',
  //   appId: '1:603070226868:web:8896fac387369774cd12e1',
  //   databaseURL: 'https://deinrufde.europe-west1.firebasedatabase.app/',
  //   storageBucket: 'app-deinrufde.appspot.com',
  //   locationId: 'europe-west',
  //   apiKey: 'AIzaSyBG8z-laflJooIGyF0Ed40XuBE-Ibfsiao',
  //   authDomain: 'app-deinrufde.firebaseapp.com',
  //   messagingSenderId: '603070226868',
  //   measurementId: 'G-3SVZTGRPBR',
  // },
  recaptcha3SiteKey: '6Le61cocAAAAADxQwHutg88wFK_zHH0JkI2ev48U',
  wordpress: {
    local: {
      user: 'info@dein-ruf.de',
      pass: '0NPRYTdIDIzYYoxk0q7noEn9',
    },
    production: {
      user: 'info@dein-ruf.de',
      pass: 'p1x9apjcSgs8BMCwkHGSlYXk',
    },
  },
  crawlerapi: {
    development: 'http://localhost:3001',
    staging: 'https://crawler.api.dein-ruf.de',
    production: 'https://crawler.api.dein-ruf.de',
  },
  nestjsapi: {
    development: 'http://localhost:3000',
    staging: 'https://client.api.dein-ruf.de',
    production: 'https://client.api.dein-ruf.de',
  },
  screenshotapi: {
    development: 'http://localhost:3002',
    staging: 'https://screenshot.api.dein-ruf.de',
    production: 'https://screenshot.api.dein-ruf.de',
  },
  google: {
    maps: {
      apiKey: 'AIzaSyAKq6QUUPwAiARn7jDm_i_hsHaTLAyEZpY',
    },
    oAuth2: {
      url: 'https://www.googleapis.com/oauth2/v4/token',
      clientId:
        '603070226868-iohghf080atvig9pbtqh1g6chts0v94k.apps.googleusercontent.com',
      clientSecret: 'O1Lto-RTQ0Ids1a5j3oSdFYm',
      redirectUri: 'https://localhost:4200',
      scope: [],
    },
  },
};
